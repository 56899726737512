import { template as template_633e98546e724cc2ab6c45305ccc5d5d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_633e98546e724cc2ab6c45305ccc5d5d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
