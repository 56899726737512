import { template as template_602048159d864a69b9bf7b690a89ab90 } from "@ember/template-compiler";
const WelcomeHeader = template_602048159d864a69b9bf7b690a89ab90(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
